import { React } from 'react'
import Header from './Addons/Header';
import Balance from './HomePageComponents/Balance';
import IncomeandExpenses from './HomePageComponents/IncomeandExpenses';
import TransactionList from './HomePageComponents/TransactionList';
import AddTransaction from './HomePageComponents/AddTransaction';
import { GlobalProvider } from './ReducerContext/Globalcontext.js';
import '../App.css';
import Layout from './Addons/Layout';
import { useAuth, AuthProvider } from './context/auth';


const MainPage = () => {
 
   
      const auth = useAuth();
      console.log('______', JSON.stringify(auth,null,4))

    return (
       
        <Layout>

      <div className='main-page'>
         <div className='container'>
                <Balance />
                <IncomeandExpenses />
              

            </div>
           <div className='container bal'>
             <TransactionList />
                <AddTransaction />
           </div>

      </div>
           
        </Layout>
       

        
    )
}

export default MainPage;

