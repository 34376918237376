import React, { useContext, useState } from 'react';
import '../../App.css';
import { NavLink } from 'react-router-dom';
import { useAuth, AuthProvider } from '../context/auth.js';
import { useNavigate , useLocation } from 'react-router-dom';
import Layout from '../Addons/Layout';
import axios from 'axios';
import toast from 'react-hot-toast';


const Login = () => {
   const { login,logout, isLoggedIn } = useAuth();
  

   const {auth,setAuth}= useAuth();
    const navigate = useNavigate();

   if(auth.token)
   {
    login();
   }

      console.log(auth);
     // logout();

     

    const [mailadd, setEmail] = useState("");
    const [passadd, setPassword] = useState();
    const location = useLocation();
   

       



    const Emailaddchange = (event) => {
        setEmail(event.target.value);
    }

    const Passaddchange = (event) => {
        setPassword(event.target.value);
    }

    const handlesubmit = async(e) =>{
    e.preventDefault();
 console.log('Inside Login');
    
       try{
      const res= await axios.post('/api/v1/auth/login', {"email": mailadd,"password": passadd});
      console.log(res);
      if(res && res.status === 200){

        await  setAuth({
          ...auth,
          user : res.data.user,
          token : res.data.token
        })
        console.log(auth);
        localStorage.setItem("auth", JSON.stringify(res.data));
       toast.success('Login successful');
     
        console.log(res.data);
        login();
      
        navigate(location.state ||'/');
        
      }
      else{
        toast.error(res.data.message);
      }
      } 
      catch(e)
      {
        console.log(e);
        toast.error("something went wrong !");
      }
    
    }
    return (
       
           <Layout>
         
             <div className='login'>
                  <form  onSubmit={handlesubmit} >

                <div className='form-control'>
                    <label htmlFor='text'>Email Address</label>
                    <input type='text' value={mailadd} onChange={Emailaddchange} placeholder='Enter your email address' />


                </div>

                <div className='form-control'>
                    <label htmlFor='Password'>Password</label>
                    <input type='text' value={passadd} onChange={Passaddchange} placeholder='*******' />
                </div>

                <button type="submit" className="btn btn-primary">Login</button>

            </form>
            <div>
                <br />
                <label>Or Register if you are new.</label>
                <NavLink className='btn' to={'/register'}>Sign up</NavLink>
            </div>
             </div>

         </Layout>

       
        
        
    )
}

export default Login
