// Contact.jsx

import React, { useState } from 'react';
import Layout from './Addons/Layout';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const Contact = () => {
  // State for form fields
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  // Handling form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handling form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your logic for handling form submission (e.g., sending feedback to a server)
    console.log('Form submitted:', formData);
    // You can also reset the form after submission
    setFormData({
      name: '',
      email: '',
      message: '',
    });
   navigate('/');
   toast.success("Thannkyou for the feedback.")
  };

  return (
   <Layout>
     <div className="container">
    
      
      <div>
        <h1>Contact Us</h1>
        <p>+91-9991029976</p>
        <br/>
        
      </div>
      <p>Please help provide your valuable feedback.</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            className="form-control"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            className="form-control"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            name="message"
            className="form-control"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </div>

        <button className="btn btn-primary" type="submit" >Submit</button>
      </form>
    </div>
   </Layout>
  );
};

export default Contact;
