import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../context/auth';
import '../../App.css';

const Header = () => {
  const {auth,setAuth}= useAuth();
    const { logout } = useAuth();

   const handleLogout = () => {
    setAuth({
      user: null,
      token: ''
    });
    localStorage.clear();
    logout();
  };



  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary fixed-header">
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo01"
          aria-controls="navbarTogglerDemo01"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
          <NavLink to="/" className="navbar-brand" href="#">
            Expense manager
          </NavLink>
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            {auth.token ? (
              // User is authenticated, show Home, User's Name, and Cart
              <>
                <li className="nav-item">
                  <NavLink to="/homepage" className="nav-link active" aria-current="page" href="#">
                    Home
                  </NavLink>
                </li>
                 <li className="nav-item">
                  <NavLink to="/contact" className="nav-link active" aria-current="page" href="#">
                    Contact us
                  </NavLink>
                </li>
                
                <li className="nav-item dropdown">
                  <NavLink
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {auth?.user?.name}
                  </NavLink>
                  <ul className="dropdown-menu">
                   
                    <li>
                      <NavLink to="/login" className="dropdown-item" onClick={handleLogout}>
                        Logout
                      </NavLink>
                    </li>
                  </ul>
                </li>
              
              </>
            ) : (
              // User is not authenticated, show Register and Login
              <>
                <li className="nav-item">
                  <NavLink to="/register" className="nav-link" href="#">
                    Register
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/login" className="nav-link" href="#">
                    Login
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
