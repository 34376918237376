import React, { useContext, useState, useEffect } from 'react';
import '../../App.css';
import {GlobalContext} from '../ReducerContext/Globalcontext.js';
import { BsFillArrowDownCircleFill } from 'react-icons/bs';
import { BsArrowDownCircle } from 'react-icons/bs';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useAuth } from '../context/auth.js';


const TransactionList = () => {

   const auth = useAuth();
  console.log('Palak', auth?.auth?.user?.id)

  const minus = 'minus';
  const plus = 'plus';

 // const userId= JSON.stringify(auth?.auth?.user?.id)

  const [flag, setflag] = useState(true);

  const[transactions, setTransactions] = useState([]);
 

   const getAllTransactions = async() =>{
    try{
      
   
   const data = await axios.get('/api/v1/transaction/transaction',{
       params : {
         userID : auth.auth.user.id
      }
     } );



        console.log('transaions___', data)
      
      if(data.data.success)
      {
        console.log('______________', data)
       setTransactions(data.data.transaction);
      }
      else 
      {
           toast.error("Error loading transactions, please try again after some time.")
      }

    } catch(e){
      console.log('error :', e);
      toast.error("Error loading transactions, please try again.")
    }

  }
    useEffect(()=>{
   getAllTransactions();
  }, [transactions]);


   const deleteTransaction = async(pid) =>{
   //  e.preventDefault();

   try{
  //  console.log(`/api/v1/Transaction/delete-Transaction/${pid}`)
    const {data} = await axios.delete(`/api/v1/transaction/delete/${pid}`);
    
    if(data.success)
    {
      toast.success("Transaction Deleted!");
      getAllTransactions();
    } else 
    {
      toast.error("Transaction is not updated.")
    }

   }catch(error)
   {
     console.log(error);
     toast.error("Something went wrong");
   }
   }


  return (
    <>
      <h3>History</h3>
      <ul id="list" className='list'>
        {transactions?.length === 0 ? <h3>This year <span >   <BsFillArrowDownCircleFill />  </span></h3> : <h3>This year <span onClick={() => setflag(false)}>  {flag && <BsFillArrowDownCircleFill />}  </span> <span onClick={() => setflag(true)}> {!flag && <BsArrowDownCircle />} </span> </h3>
        }
        {

          !flag && transactions?.map((transaction) => {

            return (
              <li className={transaction.Txntype === false ? 'minus' : 'plus'}>
                {transaction.name} <span>{transaction.amount}</span> <span className='datedisplay'>{transaction.dateoftransaction}</span> <button className='delete_button' onClick={() => deleteTransaction(transaction._id)}>x</button>

              </li>
            )
          })
        }

      </ul>

    </>
  )
}

export default TransactionList
