import { React, useState, useEffect } from 'react'
import '../../App.css';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useAuth } from '../context/auth.js';

const Balance = () => {

    const auth = useAuth();
     const[transactions, setTransactions] = useState([]);



      const getAllTransactions = async() =>{
    try{
      
   
   const data = await axios.get('/api/v1/transaction/transaction',{
       params : {
         userID : auth.auth.user.id
      }
     } );



        console.log('transaions___', data)
      
      if(data.data.success)
      {
        console.log('______________', data)
       setTransactions(data.data.transaction);
      }
      else 
      {
           toast.error("Error loading transactions, please try again after some time.")
      }

    } catch(e){
      console.log('error :', e);
      toast.error("Error loading transactions, please try again.")
    }

  }
  
 
  const plusamounttxns = transactions.filter(transaction => transaction.Txntype === true)
  const plusamount = plusamounttxns.map(plusamt => plusamt.amount);
  console.log(plusamount);
  const plustotals = plusamount.reduce((acc, item) => (acc += item), 0).toFixed(2);

  const minusamounttxns = transactions.filter(transaction => transaction.Txntype === false)
  const minusamount = minusamounttxns.map(plusamt => plusamt.amount);
  const minustotals = minusamount.reduce((acc, item) => (acc += item), 0).toFixed(2);


  const totals = plustotals - minustotals;
  

  let flag = true;
  let baltotals = totals;

  console.log(baltotals);


  const getBalannce = async(e) => {

     if (totals < 0) {
    flag = false;
    baltotals = (-1) * totals;
  }

  }
     useEffect(()=>{
   getAllTransactions();
   getBalannce();
  }, [transactions]);


  return (
    <>
      <h4>Your Balance</h4>
      <h1 id="balance"> {flag === false ? '-' : '+'} ₹{baltotals} </h1>
    </>
  )
}

export default Balance
