import { useState, useContext, useEffect, createContext } from "react";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {

  const [isLoggedIn, setLoggedIn] = useState(false);

  const login = () => setLoggedIn(true);
  const logout = () => setLoggedIn(false);

  const [auth, setAuth] = useState({
   user: null,
    token: '',
  });

  useEffect(() => {
    // Use JSON.parse, not JSON.stringify
    const data = localStorage.getItem('auth');

    if (data) {
      const parseData = JSON.parse(data);
    const updatedAuth = {
        ...auth,
        user: parseData.user,
        token: parseData.token
      };

      setAuth(updatedAuth); // Set the updated auth object
    }
  }, []); // Correctly set the dependency array

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout,auth, setAuth}}>
      {children}
    </AuthContext.Provider>
  );
}

const useAuth = () => {
   const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

export { useAuth, AuthProvider };
