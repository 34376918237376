import React, { useState, useEffect } from "react";
import "./App.css";

import MainPage from './components/MainPage';
import Login from './components/Auth/Login';
import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';
import Register from './components/Auth/Register';
import GlobalContext from "./components/ReducerContext/Globalcontext.js";
import { useAuth } from "./components/context/auth";

import Contact from "./components/Contact";

function App() {
    const { isLoggedIn } = useAuth();

 
  return (
  <>
       <Routes>
         {isLoggedIn ? (
          <Route path="/" element={<MainPage/>}/>
        ) : (
          <Route path="/" element={<Login/>} />
        )}
          <Route exact path="/register" element={<Register/>} />
          <Route exact path="/login" element={<Login/>} />
          
         
          <Route exact path="/contact" element={<Contact/>} />
          
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>  
  </>
  );
}

export default App;