import React, { useState, useContext, useEffect } from 'react';

import '../../App.css';
import {GlobalContext} from '../ReducerContext/Globalcontext.js';
import axios from 'axios';
import toast from 'react-hot-toast';

import { useAuth, AuthProvider } from '../context/auth.js';

const AddTransaction = () => {

    const{auth,setAuth} = useAuth();
    const [text, setText] = useState("");
    const [amount, setAmount] = useState();
    const [checkList, setCheckList] = useState(['Income', 'Expense']);
    const [isSelected, setIsSelected] = useState();
    const [type, setType] = useState(null);

    const AddText = (event) => {
        setText(event.target.value);
    }

    const AddAmount = (event) => {
        setAmount(event.target.value);
    }

   // const { AddTransaction } = useContext(GlobalContext);

    const onChange = (e) => {
        if (e.target.checked) {
            !isSelected && setIsSelected(e.target.name);
            setType(e.target.value);

        } else {
            setIsSelected(null);
        }
    };

    const submit = async(event) => {
        event.preventDefault();
      try{

       const TxnType = type === "Expense" ? false : true;
       const id = auth.user.id

      // console.log(id);

        const transaction = {
            name :text,
            Txntype :TxnType,
            amount: +amount,
            user:id
        }
       // console.log(transaction);
        const data = await axios.post('http://localhost:3000/api/v1/transaction/create-transaction',transaction);
        console.log(data);
      
        if(data.data.success)
       {
       setAmount(0);
        setText('');
         setIsSelected(null);
         setType(null);
        toast.success("New transaction added" );
      //  getAllTransactions();
     
      }
      else 
      {
           toast.error("Error creating transaction")
      }

        // console.log(amount, text);
      //  AddTransaction(transaction);
       
        //  setIsSelected(null);
       // setAmount(0);
       // setText('');
        
      }catch(e){
        console.log(e)
        
         toast.error("Something went wrong");
         

      }
    }

    



    return (
        <>
            <h3>Add a new Transaction</h3>

            <form onSubmit={submit}>
                <div className='form-control'>
                    <label htmlFor='text'>Text</label>
                    <input type='text' value={text} onChange={AddText} placeholder='Enter text...' />
                </div>
                <div className='form-control'>
                    <label htmlFor='amount'>Amount
                        <br />
                    </label>
                    <input type='number' value={amount} onChange={AddAmount} placeholder='Enter amount...' />

                    {
                        checkList.map((item) => {
                            return (
                                <div>
                                    < input type="checkbox" checked={isSelected} disabled={isSelected ? isSelected !== item : false} name={item} key={item} onChange={onChange}
                                        value={item} />  <span>{item}</span>
                                </div>
                            )
                        })
                    }
                </div>
                <button className='btn'>Add Transaction</button>
            </form>
        </>
    )
}

export default AddTransaction
